<!--
 * @Author: your name
 * @Date: 2020-12-01 16:16:33
 * @LastEditTime: 2021-05-16 16:12:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\backrefuse\index.vue
-->
<template>
  <div class="all" v-wechat-title="$route.meta.title">
    <p class="jujue">授权被拒</p>
    <p class="timeclass">生成中，请勿关闭该页面（{{ times }} S）</p>
    <div class="progress">
      <van-progress
        :show-pivot="showPivot"
        :stroke-width="strokeWidth"
        pivot-text="橙色"
        color="#EF811A"
        :percentage="percentage"
      />
    </div>
    <p @click="tochome">到首页逛逛</p>
    <div class="adclasses">
      <img alt="广告图片" src="../../assets/cd.jpg" />
    </div>
  </div>
</template>
<script>
import navigation from "../navigation/index.vue";
import base from "../../../util/base"
var intervalID;
export default {
  components: {
    navigation,
  },
  mounted() {
    intervalID = setInterval(() => {
      this.times--;
      this.percentage += 3.333333;

      if (this.times < 1) {
        clearInterval(intervalID);
        setTimeout(() => {
          window.location = base.tourlc;
        }, 3000);
      }
    }, 1000);
  },
  destroyed() {
    clearInterval(intervalID);
  },
  data() {
    return {
      msg: "授权成功",
      aa: "noleft",
      color: "red",
      showPivot: false, //是否显示进度条内容
      strokeWidth: ".37rem",
      times: 30, //剩余时间
      percentage: 0, //进度条长度
    };
  },
  methods:{
    tochome(){
      window.location = base.tourlc;
    }
  }
};
</script>
<style lang="postcss" scoped>
.all {
  height: 100%;
  background-color: #ffffff;
}
.jujue {
  text-align: center;
  padding-top: 0.2rem;
  color: #ef811a;
  font-size: 0.34rem;
  font-weight: 500;
}
.timeclass {
  text-align: center;
  font-size: 0.3rem;
  color: #222222;
  margin-top: 0.46rem;
  font-weight: 500;
}
.progress {
  margin-top: 0.17rem;
  margin: 0.3rem;
  border-radius: 0.6rem;
  overflow: hidden;
}
.progress + p {
  text-align: center;
  font-size: 0.3rem;
  color: #409EFF;
}
.adclasses {
  text-align: center;
  margin-top: 0.5rem;
}
.adclasses img {
  width: 6.9rem;
  height: 10.42rem;
  border-radius: 10px;
}
</style>